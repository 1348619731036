@media print {
	.no-print {
		display: none !important;

		 * {
			display: none !important;
		}
	}

	a {
		color: black;
		text-decoration: none;
	}
}
